<template lang="pug">
  v-card.v-card--plan.mx-auto.pt-0.pb-4.px-2.text-center(max-width="100%" flat)
    // Avatar
    v-row(:class="color(plan)" dark).popular-tag__header.white--text
      v-col(cols="12").popular-tag__container
        div(v-if="plan.nickname === 'Advance'").text-uppercase.popular-tag Popular
        h2.text-uppercase(v-text="plan.nickname").popular-tag__title
      // Title
    .display-4.pt-3
      | {{plan.amount | moneyFormat}}
    h2(v-if="plan.nickname !== 'A SINGLE IMAGE' && plan.nickname !== 'A SINGLE VIDEO'") MONTHLY
    h4(v-else) One Time
    // Metadata
    span(v-for="(value, key) in plan.metadata")
      p.body-1.font-weight-light.py-4 {{value}} {{key}}
      v-divider(opacity="1")
    // Description
    div(v-if="plan.nickname !== 'A SINGLE IMAGE' && plan.nickname !== 'A SINGLE VIDEO'")
      div(v-for="(description, key) in descriptions")
        p.body-1.font-weight-light.py-4 {{description}}
        v-divider
      div(v-if="plan.nickname === 'Premium'")
        p.body-1.font-weight-light.py-4 VIP Service
        v-divider
    div(v-else)
      div(v-for="(description, key) in descriptionsPayments")
        p.body-1.font-weight-light.py-4 {{description}}
        v-divider
    // Button
    v-btn(:color="'primary lighten'" outlined @click="selectPlan(plan)").mt-5.pt-2
      span.px-4(v-if="plan.nickname !== 'A SINGLE IMAGE' && plan.nickname !== 'A SINGLE VIDEO'") {{ $t('plan') }}
      span.px-4(v-else) Buy now
</template>

<script>

  const formatter = new Intl.NumberFormat('es-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    minimumIntegerDigits: 1,
  })

  export default {
    name: 'PagesPlanCard',

    filters: {
      moneyFormat: function (value) {
        const entero = value.toString().slice(0, -2)
        const decimal = value.toString().slice(-2)
        return formatter.format(entero.concat('.', decimal))
      },
    },

    components: {
      PagesBtn: () => import('./Btn'),
    },

    props: {
      plan: {
        type: Object,
        default: () => ({
          best: false,
          heading: undefined,
          icon: undefined,
          title: undefined,
          text: undefined,
        }),
      },
    },

    data () {
      return {
        descriptions: [
          'Unlimited Collaborators Account',
          'Customer Service Support',
          'Unlimited Access to purchased content',
          'Full access to all contents Available',
          'Unlimited content sharing and downloads',
          'Standard license provided ',
          'Autorenewal with easy opt-out',
        ],
        descriptionsPayments: [
          'Best for individual',
          'Full access to content after purchase',
        ],
      }
    },

    methods: {
      selectPlan (plan) {
        const vm = this
        if (plan.nickname !== 'A SINGLE IMAGE' && plan.nickname !== 'A SINGLE VIDEO') {
          vm.$store.dispatch('subscriptions/setPlan', plan).then(function () {
            // ('listo')
            vm.$router.replace('/subscriptions')
          })
        } else {
          vm.$router.replace('/search')
        }
      },
      color (plan) {
        let color = 'primary'
        switch (plan.nickname) {
          case 'Premium':
            color = 'secondary darken-3'
            break
          case 'Advance':
            color = 'primary'
            break
          default:
            color = 'secondary lighten'
            break
        }
        return color
      },
    },
  }
</script>

<style lang="scss">
  .v-card--plan {
    overflow: hidden;
    .v-avatar {
      border-radius: 50%;
      border: 1px solid #E5E5E5;
    }
  }
  .popular-tag{
    &__header{
      height: 76px;
    }
    &__title{
      align-items: center;
      display: flex;
    }
    &__container{
      overflow: hidden;
      position: relative;
      display: flex;
      align-content: center;
      justify-content: center;
    }
    position: absolute;
    top: 18px;
    right: -30px;
    background: black;
    color: white;
    font-size: 14px;
    padding: 2px 30px;
    transform: rotate(45deg);
  }
</style>
